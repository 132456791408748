import React from "react"
import styled from "styled-components"
import moment from "moment"
import { colors } from "../../style/colors"

const TableContainer = styled.div`
    overflow-x: auto;
`
const BookingTable = styled.table``
const TableRow = styled.tr`
    cursor: pointer;

    :hover {
        background: #fff9fa;
    }
`
const TableHeader = styled.th`
    font-size: 0.9rem;
    color: ${colors.main};
    font-weight: 600;
    font-family: "Work Sans";
`
const TableDetail = styled.td`
    font-size: 1rem;
    font-family: "Work Sans";
    font-weight: 400;

    color: ${props => props.status && "#6BA296"};
    font-weight: ${props => props.status && 600};
`

const BookingsTable = ({
    bookings,
    handleBookingSelect = { handleBookingSelect },
}) => (
    <TableContainer>
        <BookingTable>
            <thead>
                <TableRow>
                    <TableHeader>Customer Details</TableHeader>
                    <TableHeader>Booked Date</TableHeader>
                    <TableHeader>Space</TableHeader>
                    <TableHeader>Event Type</TableHeader>
                    <TableHeader>Status</TableHeader>
                </TableRow>
            </thead>
            <tbody>
                {bookings.map((booking, index) => (
                    <TableRow
                        key={index}
                        onClick={() => handleBookingSelect(booking)}
                    >
                        <TableDetail>
                            <b>{booking.fullName}</b> <br />
                            <span style={{ color: "#656565", fontWeight: 500 }}>
                                {booking.phoneNumber}
                            </span>
                        </TableDetail>
                        <TableDetail>
                            {moment(booking.date)
                                .format("Do MMM, YYYY")
                                .toString()}
                        </TableDetail>
                        <TableDetail>{booking.space}</TableDetail>
                        <TableDetail>Wedding</TableDetail>
                        <TableDetail status>
                            {booking.status ? "Confirmed" : "Unconfirmed"}
                        </TableDetail>
                    </TableRow>
                ))}
            </tbody>
        </BookingTable>
    </TableContainer>
)

export default BookingsTable
