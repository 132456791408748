import React, { Component, useEffect, useState } from "react"
import styled from "styled-components"
import { colors } from "../style/colors"
import CurrentDate from "./dashboard/current-date"
import SearchBar from "./dashboard/search-bar"
import { GetAllBookings } from "../services/adminApi"
import AcceptedBookings from "./dashboard/accepted-bookings"
import { getBookingsByStatus } from "../utils/utils"
import UnconfirmedBookings from "./dashboard/unconfirmed-bookings"
import BookingDetails from "./dashboard/bookings-details"
import { motion } from "framer-motion"
import Modal from "react-modal"

Modal.setAppElement("#___gatsby")

const Wrapper = styled.div`
    width: 100%;

    min-height: 100vh;
`
const NavWrapper = styled.div`
    background: ${colors.beige};
    width: 50px;
`
const MainContent = styled.div`
    flex: 1;
    padding: 50px 20px;
    max-width: 1200px;
    margin: 0 auto;

    @media (max-width: 550px) {
    }
`
const ExtraInfoContainer = styled(motion.div)`
    background: ${colors.beige};
    /* width: 280px; */
    /* width: 0; */
`
const Container = styled.div`
    /* padding: 30px; */
`

const variants = {
    visible: { opacity: 1, width: 300 },
    hidden: { opacity: 0, width: 0 },
}

const customStyles = {
    overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(255, 255, 255, 0.9)",
    },
    content: {
        width: "80%",
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
    },
}

const Dashboard = () => {
    const [bookings, setBookings] = useState([])
    const [activeBooking, setActiveBooking] = useState(null)

    useEffect(() => {
        GetAllBookings().then(res => {
            if (!res.success) {
                alert(res.error)
            } else {
                setBookings(res.bookings)
            }
        })
    }, [])

    const handleBookingSelect = booking => {
        setActiveBooking(null)
        console.log(booking)
        setTimeout(() => {
            setActiveBooking(booking)
        }, 500)
    }

    const dismiss = () => {
        setActiveBooking(null)
    }

    return (
        <Wrapper>
            <MainContent>
                <Container>
                    <CurrentDate />
                    <SearchBar />
                    <AcceptedBookings
                        bookings={getBookingsByStatus(bookings, true)}
                        handleBookingSelect={handleBookingSelect}
                    />
                    <UnconfirmedBookings
                        bookings={getBookingsByStatus(bookings, false)}
                        handleBookingSelect={handleBookingSelect}
                    />
                </Container>
            </MainContent>
            {/* <ExtraInfoContainer
                initial={{ width: 0 }}
                variants={variants}
                animate={activeBooking ? "visible" : "hidden"}
            >
                {activeBooking && <BookingDetails booking={activeBooking} />}
            </ExtraInfoContainer> */}
            <Modal
                isOpen={activeBooking ? true : false}
                onRequestClose={dismiss}
                style={customStyles}
                contentLabel="Example Modal"
            >
                {activeBooking && <BookingDetails booking={activeBooking} />}
            </Modal>
        </Wrapper>
    )
}

export default Dashboard
