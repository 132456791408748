import Parse from "parse"
import axios from "axios"

//Initialize Parse
Parse.initialize(
    "09NLkiclRtAZmeoGlR9M9W0nuarlh4Wx0fEFM81R",
    "nIw2GpFWThv6hNbKaipdLjflbAxLt560l7j4V3Ef"
)
Parse.serverURL = "https://parseapi.back4app.com/"

export async function currentUserCheck() {
    var currentUser = Parse.User.current()
    if (currentUser) {
        // Parse.User.logOut()
        return true
    } else {
        return false
    }
}

export async function authenticateClient(username, password) {
    try {
        const user = await Parse.User.logIn(username, password)
        return true
    } catch (err) {
        return err.message
    }
}

export async function GetAllBookings() {
    const Booking = Parse.Object.extend("Booking")
    const query = new Parse.Query(Booking)

    query.ascending("createdAt")

    try {
        const results = await query.find()
        const bookings = results.map(booking => {
            return {
                id: booking.id,
                space: booking.get("space"),
                createdAt: booking.createdAt,
                price: booking.get("price"),
                date: booking.get("date"),
                guestCount: booking.get("guestCount"),
                phoneNumber: booking.get("phoneNumber"),
                additionalOptions: booking.get("additionalOptions"),
                fullName: booking.get("fullName"),
                email: booking.get("email"),
                status: booking.get("status"),
            }
        })

        return {
            success: true,
            bookings,
        }
    } catch ({ message }) {
        return {
            success: false,
            error: message,
        }
    }
}
