import React, { Component } from "react"
import styled from "styled-components"

//STYlE
import { colors } from "../../style/colors"

//COMPONENTS
import FloatingOrderTotal from "../floating-order-total"
import CheckboxInput from "../checkbox-input"
import IntroSection from "../booking-screen/intro-section"

//NPM
import { motion, AnimatePresence } from "framer-motion"
import "react-dates/initialize"
import "react-dates/lib/css/_datepicker.css"
import {
  DateRangePicker,
  SingleDatePicker,
  DayPickerRangeController,
} from "react-dates"
import "../../style/react_dates_overrides.css"
import { navigate } from "gatsby"
import moment from "moment"
import StickyOrderTotal from "../sticky-order-total"

//Containers
const Container = styled.div`
  padding: 50px;

  @media (max-width: 550px) {
    padding: 50px 30px;
  }
`
const Section = styled(motion.section)``
const CountContainer = styled(motion.div)`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 20px;
`
const CountBoxes = styled(motion.div)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media (max-width: 550px) {
    flex-wrap: nowrap;
    overflow: scroll;
    justify-content: flex-start;
    padding-left: 10px;
  }
`
const CountBox = styled.div`
  border: 1px solid #d7d7d7;
  border-radius: 8px;
  padding: 15px;
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  transition: 0.3s ease;

  @media (max-width: 550px) {
    padding: 10px;
    margin: 0 10px 40px 0;
  }

  background: ${props => props.selected && colors.accent};

  :hover {
    background: ${colors.accent};
  }

  margin: 20px 10px;

  :first-child {
    margin-left: 0;
  }

  :last-child {
    margin-right: 0;
  }
`
const DateContainer = styled.div`
  margin-bottom: 500px;
`
const Icons = styled.div`
  display: flex;
  flex-direction: row;
  transition: 0.3s ease;

  @media (max-width: 550px) {
    display: none;
  }

  filter: ${props =>
    props.selected &&
    `invert(100%) sepia(100%) saturate(2%) hue-rotate(73deg)
      brightness(114%) contrast(101%)`};

  ${CountBox}:hover & {
    filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(73deg)
      brightness(114%) contrast(101%);
  }
`

//Text

const Break = styled.hr`
  background: #f2f0f0;
`
const SectionTitle = styled.h5`
  color: #484848;
  font-weight: 600;
  font-family: "Work Sans";
  font-size: 16px;
  margin-bottom: 20px;
`
const Copy = styled.p`
  color: #353535;
  font-size: 18px;
  max-width: 600px;

  @media (max-width: 1100px) {
    max-width: 500px;
  }
`
const CountLabel = styled.p`
  margin: 0;
`
const BoxLabel = styled.p`
  margin: 0;
  font-weight: 600;
  color: ${props => (props.selected ? "white" : "#4a4a4a")};
  transition: 0.3s ease;

  ${CountBox}:hover & {
    color: white;
  }
`

//Input
const CountInput = styled.input`
  border: none;
  border-bottom: 2px solid ${colors.accent};
  font-family: "Work Sans";
  font-weight: 600;
  font-size: 36px;
  height: 60px;
  width: 150px;
  color: #5a5a5a;
  margin-right: 5px;
  border-radius: 0;
  -webkit-border-radius: 0px;

  :focus {
    outline: none;
  }
`

//Images
const Icon = styled.img`
  height: 30px;
  width: 15px;
  object-fit: contain;
`

class BookingWrapper extends Component {
  constructor(props) {
    super(props)

    this.guestsRef = React.createRef()
    this.hardwareRef = React.createRef()
    this.extraOptionsRef = React.createRef()

    this.state = {
      date: null,
      guestCount: "",
      selectGuestRange: false,
      hardwarePackageSelected: false,
      additionalServices: [],
      isMobile: false,
      loading: false,
      fullName: "",
      email: "",
      phoneNumber: "",
    }
  }

  componentDidMount() {
    this.resizeHandler()

    if (typeof window !== "undefined") {
      window.addEventListener("resize", this.resizeHandler)
      if (window.innerWidth > 500) {
        this.setState({ isMobile: false })
      } else {
        this.setState({ isMobile: true })
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeHandler, false)
    clearInterval(this.timer)
  }

  resizeHandler = () => {
    console.log("Handler")
    const { isMobile } = this.state
    let width = window.innerWidth

    if (width < 500 && !isMobile) {
      this.setState({ isMobile: true })
    }
    if (width > 500 && isMobile) {
      this.setState({ isMobile: false })
    }
  }

  handleDateSelect = date => {
    console.log(date)
    this.setState({ date }, () => {
      //Scroll to guest view
      this.guestsRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      })
    })
  }

  handleSelectGuestRange = () => {
    this.setState({ selectGuestRange: !this.state.selectGuestRange }, () => {
      //If set to true, clear the current guest count
      if (this.state.selectGuestRange) {
        this.setState({ guestCount: null })
      }
    })
  }

  handleSetGuestCountRange = range => {
    this.setState({ guestCount: range })
  }

  updateInput = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  confirmBooking = () => {
    this.setState({ loading: true })
    const { date, guestCount, fullName, email, phoneNumber } = this.state

    //Add them to the mailing list NOW

    //Add a delay so the user knows something is happening
    setTimeout(() => {
      navigate("/booking-summary", {
        state: {
          date: moment(date)
            .format("dddd MMMM Do, YYYY")
            .toString(),
          rawDate: moment(date).toDate(),
          guestCount: guestCount,
          fullName: fullName,
          email: email,
          phoneNumber: phoneNumber,
        },
      })
    }, 1500)
  }

  getBookedDates = () => {
    //get dates that are already booked so we can display them as unavailable dates
    console.log("Something")
  }

  render() {
    const { title } = this.props
    const {
      date,
      guestCount,
      additionalServices,
      selectGuestRange,
      isMobile,
      loading,
      fullName,
      email,
      phoneNumber,
    } = this.state
    return (
      <Container>
        <FloatingOrderTotal
          guestCount={guestCount}
          date={date}
          loading={loading}
          extras={additionalServices}
          confirmBooking={this.confirmBooking}
          fullName={fullName}
          email={email}
          phoneNumber={phoneNumber}
          updateInput={this.updateInput}
        />
        <StickyOrderTotal
          guestCount={guestCount}
          date={date}
          loading={loading}
          extras={additionalServices}
          confirmBooking={this.confirmBooking}
          fullName={fullName}
          email={email}
          phoneNumber={phoneNumber}
          updateInput={this.updateInput}
        />
        {/* TODO: Change this title to pull based on props */}
        <IntroSection title={title} />
        <Break />

        <Section
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.1, duration: 1.3 }}
          visible
        >
          <SectionTitle>Here's why people love {title}</SectionTitle>
          <Copy>
            Featuring over 25 acres of beautiful land and scenery, it’s no
            wonder why this space is a top choice for our clients. Lorem ipsum
            dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua. Lacus luctus accumsan
            tortor posuere ac ut consequat semper. Felis bibendum ut tristique
            et.{" "}
          </Copy>
        </Section>

        <Break />

        <Section
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.1, duration: 1.3 }}
          visible
        >
          <SectionTitle>When do you want to book for?</SectionTitle>
          <Copy>
            The date you select allows you to have the space for the entire day.
          </Copy>
          <DateContainer>
            <SingleDatePicker
              date={this.state.date} // momentPropTypes.momentObj or null
              onDateChange={date => this.handleDateSelect(date)} // PropTypes.func.isRequired
              focused
              daySize={isMobile ? 40 : 50}
              small={isMobile ? true : false}
              orientation={isMobile ? "horizontal" : "horizontal"}
              placeholder="Select a date"
              numberOfMonths={1}
              hideKeyboardShortcutsPanel
              onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
              id="calendar"
              regular={false}
              renderCalendarDay={undefined}
              renderDayContents={null}
              renderMonthText={null}
              reopenPickerOnClearDate={false}
              required={false}
              screenReaderInputMessage=""
              showClearDate={false}
              showDefaultInputIcon={false}
              small={true}
              verticalSpacing={20}
              withFullScreenPortal={false}
              withPortal={false}
              anchorDirection="left"
              block={false}
              customInputIcon={null}
              disabled={false}
              displayFormat={function noRefCheck() {}}
              enableOutsideDays={false}
              horizontalMargin={5}
              initialVisibleMonth={null}
              isDayBlocked={function noRefCheck() {}}
              isDayHighlighted={function noRefCheck() {}}
              isOutsideRange={function noRefCheck() {}}
            />
          </DateContainer>
        </Section>

        <AnimatePresence>
          {/* User has selected a date */}
          {date && (
            <>
              <Break />
              <Section
                ref={this.guestsRef}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0, y: 20 }}
              >
                <SectionTitle>How many guests will be attending?</SectionTitle>
                <Copy>
                  An accurate guest counts helps us when considering things like
                  seating and utensils.
                </Copy>
                <AnimatePresence exitBeforeEnter>
                  {!selectGuestRange && (
                    <CountContainer
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 0.5 }}
                      exit={{ opacity: 0 }}
                      positionTransition
                    >
                      <CountInput
                        onChange={this.updateInput}
                        name="guestCount"
                        value={guestCount}
                        placeholder="eg. 25"
                        type="number"
                      />
                      <CountLabel>guests</CountLabel>
                    </CountContainer>
                  )}
                </AnimatePresence>
                <CheckboxInput
                  clickHandler={this.handleSelectGuestRange}
                  title="I'm not sure yet, let me select a range"
                />
                <AnimatePresence>
                  {selectGuestRange && (
                    <CountBoxes
                      positionTransition
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 0.5 }}
                      exit={{ opacity: 0 }}
                    >
                      <CountBox
                        selected={guestCount === "25-100"}
                        onClick={() => this.handleSetGuestCountRange("25-100")}
                      >
                        <Icons selected={guestCount === "25-100"}>
                          <Icon src={require("../../images/icon-person.png")} />
                        </Icons>
                        <BoxLabel selected={guestCount === "25-100"}>
                          25-100 persons
                        </BoxLabel>
                      </CountBox>
                      <CountBox
                        selected={guestCount === "100-300"}
                        onClick={() => this.handleSetGuestCountRange("100-300")}
                      >
                        <Icons selected={guestCount === "100-300"}>
                          <Icon src={require("../../images/icon-person.png")} />
                          <Icon src={require("../../images/icon-person.png")} />
                        </Icons>
                        <BoxLabel selected={guestCount === "100-300"}>
                          100-300 persons
                        </BoxLabel>
                      </CountBox>
                      <CountBox
                        selected={guestCount === "300+"}
                        onClick={() => this.handleSetGuestCountRange("300+")}
                      >
                        <Icons selected={guestCount === "300+"}>
                          <Icon src={require("../../images/icon-person.png")} />
                          <Icon src={require("../../images/icon-person.png")} />
                          <Icon src={require("../../images/icon-person.png")} />
                        </Icons>
                        <BoxLabel selected={guestCount === "300+"}>
                          300+ persons
                        </BoxLabel>
                      </CountBox>
                    </CountBoxes>
                  )}
                </AnimatePresence>
              </Section>
            </>
          )}
        </AnimatePresence>
      </Container>
    )
  }
}

export default BookingWrapper
