import React, { Component } from "react"
import styled from "styled-components"
import MainButton from "../components/main-button"
import Loader from "./loader"
import { authenticateClient } from "../services/adminApi"
import { navigate } from "gatsby"

const Container = styled.div`
    max-width: 500px;
    margin: 0 auto;
    padding: 30px;
    height: 100vh;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
const Title = styled.h1``
const Input = styled.input`
    width: 100%;
    margin-bottom: 10px;
    border-radius: 2px;
    border: 1px solid #e3e0e0;
    display: block;
    font-family: "Work Sans";
    font-weight: 300;
    font-size: 16px;
    padding: 10px;
`

class Login extends Component {
    state = {
        username: "",
        password: "",
        loading: false,
    }

    updateInput = e => {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleSubmit = e => {
        this.setState({ loading: true })
        const { username, password } = this.state

        authenticateClient(username, password).then(res => {
            this.setState({ loading: false })
            if (res === true) {
                console.log("Welcome!")

                // Refresh the page?
                window.location.reload()
            } else {
                alert(res)
            }
        })
    }

    render() {
        const { username, password, loading } = this.state
        return (
            <Container>
                <Title>Log in to La Soledad</Title>
                <Input
                    name="username"
                    value={username}
                    onChange={this.updateInput}
                    type="text"
                    placeholder="Username"
                />
                <Input
                    name="password"
                    value={password}
                    onChange={this.updateInput}
                    type="password"
                    placeholder="Password"
                />
                <MainButton clickHandler={this.handleSubmit} title="Log In" />
                {loading && <Loader />}
            </Container>
        )
    }
}

export default Login
