import React from "react"
import styled from "styled-components"
import { Subtitle } from "../../style/typography"
import moment from "moment"
import { colors } from "../../style/colors"

const Date = styled.h2`
    color: ${colors.main};
`

const CurrentDate = () => (
    <>
        <Subtitle>Today</Subtitle>
        <Date>
            {moment()
                .format("dddd Do MMMM")
                .toString()}
        </Date>
    </>
)
export default CurrentDate
