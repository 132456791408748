import Parse from "parse"
import axios from "axios"

//Initialize Parse
Parse.initialize(
  "09NLkiclRtAZmeoGlR9M9W0nuarlh4Wx0fEFM81R",
  "nIw2GpFWThv6hNbKaipdLjflbAxLt560l7j4V3Ef"
)
Parse.serverURL = "https://parseapi.back4app.com/"

const BOOKING_RETURN_URL = "https://flamboyant-shockley-3687fb.netlify.com/"

export async function AddToMailingList(email, name) {}

export async function CreateNewBooking(
  date,
  fullName,
  email,
  phone,
  space,
  guestCount,
  priceEstimate,
  additionalOptions
) {
  const Booking = Parse.Object.extend("Booking")
  const booking = new Booking()

  booking.set("fullName", fullName)
  booking.set("date", date)
  booking.set("email", email)
  booking.set("phoneNumber", phone)
  booking.set("space", space)
  booking.set("guestCount", guestCount)
  booking.set("price", priceEstimate)
  booking.set("additionalOptions", additionalOptions)

  //Save the booking and get the OBJECT ID

  try {
    const savedBooking = await booking.save()
    return {
      success: true,
      bookingId: savedBooking.id,
    }
  } catch (err) {
    return {
      error: err.message,
    }
  }
}
