import React from "react"
import styled from "styled-components"
import { Subtitle } from "../../style/typography"
import MainButton from "../main-button"
import { colors } from "../../style/colors"
import moment from "moment"
import { FaPhone } from "react-icons/fa"
import { MdEmail } from "react-icons/md"

const Container = styled.div`
    padding: 50px 10px;
    overflow-y: scroll;
`

const LineItem = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`
const LineTitle = styled.h3`
    color: ${colors.main};
    font-family: "Work Sans";
    font-weight: 400;
    font-size: 18px;

    @media (max-width: 550px) {
        font-size: 15px;
        margin-bottom: 10px;
    }
`
const LinePrice = styled.p`
    color: ${colors.main};
    font-family: "Work Sans";
    font-weight: 400;
    font-size: 18px;

    @media (max-width: 550px) {
        font-size: 15px;
        margin-bottom: 10px;
    }
`
const ActionButton = styled.button`
    border: 1px solid ${colors.main};
    text-transform: uppercase;
    letter-spacing: 2px;
    font-family: "Work Sans";
    width: 100%;
    border-radius: 5px;
    padding: 20px 10px;
    color: ${colors.main};
    font-size: 15px;
    cursor: pointer;
    background: none;
    transition: 0.3s ease-in;
    margin-bottom: 15px;

    :hover {
        background: ${colors.main};
        color: white;
    }

    @media (max-width: 550px) {
        padding: 15px 10px;
        font-size: 14px;
    }
`
const ContactItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`
const ContactLink = styled.a`
    font-family: "Work Sans";
    color: ${colors.main};
    text-decoration: underline;
    font-weight: 300;
    margin-left: 15px;
    font-size: 16px;
`

const BookingDetails = ({ booking }) => (
    <Container>
        <Subtitle>Booked By</Subtitle>
        <LineTitle>{booking.fullName}</LineTitle>

        <Subtitle>Date</Subtitle>
        <LineTitle>
            {moment(booking.date)
                .format("dddd Do MMMM, YYYY")
                .toString()}
        </LineTitle>

        <Subtitle>Space</Subtitle>
        <LineTitle>{booking.space}</LineTitle>

        <ContactItem>
            <FaPhone color={colors.main} />{" "}
            <ContactLink href={`tel:${booking.phoneNumber}`}>
                {booking.phoneNumber}
            </ContactLink>
        </ContactItem>
        <ContactItem>
            <MdEmail color={colors.main} />{" "}
            <ContactLink href={`mailto: ${booking.email}`}>
                {booking.email}
            </ContactLink>
        </ContactItem>

        <hr />

        <Subtitle>Line Items</Subtitle>
        <LineItem>
            <LineTitle>{booking.guestCount} guests</LineTitle>
            <LinePrice>$10,000.00</LinePrice>
        </LineItem>

        {booking.additionalOptions &&
            booking.additionalOptions.map((item, index) => (
                <LineItem key={index}>
                    <LineTitle>{item}</LineTitle>
                </LineItem>
            ))}

        <ActionButton>Add line Item</ActionButton>
        <ActionButton>Edit Booking</ActionButton>
    </Container>
)

export default BookingDetails
