import styled from "styled-components"
import { colors } from "./colors"

export const Break = styled.hr`
    background: #f2f0f0;
`

export const SectionTitle = styled.h5`
    color: #484848;
    font-weight: 600;
    font-family: "Work Sans";
    font-size: 16px;
    margin-bottom: 10px;
`

export const Subtitle = styled.h5`
    color: #6d6d6d;
    font-weight: 600;
    font-family: "Work Sans";
    font-size: 12px;
    margin-bottom: 5px;
    text-transform: uppercase;
`
export const TableTitle = styled.h1`
    color: ${colors.main};
    font-size: 28px;
`
