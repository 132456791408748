import React from "react"
import styled from "styled-components"
import { GoSearch } from "react-icons/go"

const SearchContainer = styled.div`
    margin-bottom: 80px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid #ededed;
    border-radius: 8px;
    padding: 15px 20px;
`
const SearchInput = styled.input`
    width: 100%;
    border: none;
    font-family: "Work Sans";
    font-weight: 300;
    margin-left: 20px;

    :focus {
        outline: none;
    }
`

const SearchBar = props => (
    <SearchContainer>
        <GoSearch color="#B4B4B4" size={24} />
        <SearchInput placeholder="Search by name, date or space" />
    </SearchContainer>
)

export default SearchBar
