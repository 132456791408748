import React from "react"
import styled from "styled-components"
import { colors } from "../style/colors"

const Button = styled.button`
    border: 1px solid ${colors.main};
    text-transform: uppercase;
    letter-spacing: 2px;
    font-family: "Work Sans";
    min-width: 250px;
    border-radius: 5px;
    padding: 20px 10px;
    color: ${colors.main};
    font-size: 15px;
    cursor: pointer;
    background: none;
    transition: 0.3s ease-in;
    margin-bottom: 15px;

    :hover {
        background: ${colors.main};
        color: white;
    }

    @media (max-width: 550px) {
        padding: 15px 10px;
        font-size: 14px;
    }
`

const MainButton = ({ title, clickHandler }) => (
    <Button onClick={() => clickHandler()}>{title}</Button>
)

export default MainButton
