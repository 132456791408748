import React from "react"
import styled from "styled-components"
import { TableTitle } from "../../style/typography"
import BookingsTable from "./bookings-table"

const Container = styled.section`
    margin: 25px 0;
`

const AcceptedBookings = ({ bookings, handleBookingSelect }) => (
    <Container>
        <TableTitle>Accepted Bookings ({bookings.length})</TableTitle>
        <BookingsTable
            bookings={bookings}
            handleBookingSelect={handleBookingSelect}
        />
    </Container>
)

export default AcceptedBookings
