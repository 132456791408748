import React from "react"
import styled from "styled-components"

//COMPONENTS
import Layout from "../components/layout"
import Login from "../components/login"
import Dashboard from "../components/dashboard"
import { currentUserCheck } from "../services/adminApi"
import Loader from "../components/loader"

class IndexPage extends React.Component {
    state = {
        loading: true,
        loggedIn: false,
    }

    async componentDidMount() {
        const loggedIn = await currentUserCheck()
        console.log("Logged in: " + loggedIn)

        this.setState({ loggedIn, loading: false })
    }

    render() {
        const { loading, loggedIn } = this.state

        if (loading) {
            return (
                <Layout home>
                    <Loader />
                </Layout>
            )
        }

        return <Layout home>{loggedIn ? <Dashboard /> : <Login />}</Layout>
    }
}

export default IndexPage
